import { ref } from "vue"
import { auth, db } from "../firebase/config"
import { doc, updateDoc } from "firebase/firestore";

const updateProfile = async (profileInfo) => {
    const profError = ref(null)
    try {
        // await db.collection("onco").doc(auth.currentUser.email).update(profileInfo)
        await updateDoc(doc(db, "onco", auth.currentUser.email), profileInfo)
    } catch (err) {
        console.log(err);
        profError.value = err.message
    }
    return { profError }
}
export default updateProfile